import Typography from 'typography'

const typography = new Typography({
  baseFontSize: '18px',
  baseLineHeight: 1.666,
  headerFontFamily: [
    'Exo 2',
    'Helvetica Neue',
    'Helvetica',
    'Arial',
    'sans-serif',
  ],
  bodyFontFamily: [
    'Lato',
    'Helvetica Neue',
    'Helvetica',
    'Arial',
    'sans-serif',
  ],
})

export default typography
