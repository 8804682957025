import { createGlobalStyle } from 'styled-components'
import typography from '../utils/typography'

const GlobalStyle = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    color: rgba(0, 0, 0, .87);
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: ${typography.bodyFontFamily};
    vertical-align: baseline;
  }

  /* Added to Fix Footer to bottom of viewport */
  html, body {
    height: 100%;
  }
  .siteRoot {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .siteContent {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }
  footer {
    width: 100%;
  }

  /* End Fix to Place Footer on Bottom of Viewport */

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  @media screen and (min-width: 35em) {
    html {
      margin-right: calc(-100vw + 100%);
      overflow-x: hidden;
    }
  }

  ol, ul, li {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote::before, blockquote::after,
  q::before, q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }

  body {
    background: #fbf5f5;
    line-height: 1;
    font-size: 100%;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    font-weight: 400;
    font-family: ${typography.bodyFontFamily};
  }

  img {
    display: block;
  	width: 100%;
  	height: auto;
  }

  button,
  input {
    font-family: inherit;
    font-size: inherit;
    background: none;
    border: none;
    outline: none;
    appearance: none;
    border-radius: 0;
    resize: none;
    &:focus {
      outline: none;
    }
    &:invalid {
      box-shadow: none;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${typography.headerFontFamily};
    font-weight: 700;
    text-transform: uppercase;
  }

  /* exo-2-regular - latin */
  @font-face {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/exo2/exo-2-v20-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local(''),
      url('/fonts/exo2/exo-2-v20-latin-regular.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('/fonts/exo2/exo-2-v20-latin-regular.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('/fonts/exo2/exo-2-v20-latin-regular.woff') format('woff'),
      /* Modern Browsers */
      url('/fonts/exo2/exo-2-v20-latin-regular.ttf') format('truetype'),
      /* Safari, Android, iOS */
      url('/fonts/exo2/exo-2-v20-latin-regular.svg#Exo2') format('svg');
    /* Legacy iOS */
  }

  /* exo-2-700 - latin */
  @font-face {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/exo-2/exo-2-v20-latin-700.eot');
    /* IE9 Compat Modes */
    src: local(''),
      url('/fonts/exo-2/exo-2-v20-latin-700.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('/fonts/exo-2/exo-2-v20-latin-700.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('/fonts/exo-2/exo-2-v20-latin-700.woff') format('woff'),
      /* Modern Browsers */
      url('/fonts/exo-2/exo-2-v20-latin-700.ttf') format('truetype'),
      /* Safari, Android, iOS */
      url('/fonts/exo-2/exo-2-v20-latin-700.svg#Exo2') format('svg');
    /* Legacy iOS */
  }

  /* lato-regular - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/lato/lato-v23-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local(''),
      url('/fonts/lato/lato-v23-latin-regular.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('/fonts/lato/lato-v23-latin-regular.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('/fonts/lato/lato-v23-latin-regular.woff') format('woff'),
      /* Modern Browsers */
      url('/fonts/lato/lato-v23-latin-regular.ttf') format('truetype'),
      /* Safari, Android, iOS */
      url('/fonts/lato/lato-v23-latin-regular.svg#Lato') format('svg');
    /* Legacy iOS */
  }

  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/material_icons.woff2) format('woff2');
  }

  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
`
export default GlobalStyle
