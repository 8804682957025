module.exports = {
  siteTitle: 'Tinongo Blog',
  siteTitleAlt: 'Tinongo Blog – Finde den Sport für Dein Kind.', // This allows an alternative site title for SEO schema.
  publisher: 'Tinongo', // Organization name used for SEO schema
  siteDescription: 'Der Tinongo Blog.',
  siteUrl: 'https://blog.tinongo.org', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  postsPerHomePage: 7, // Number of posts shown on the 1st page of of the index.js template (home page)
  postsPerPage: 6, // Number of posts shown on paginated pages
  author: 'Tinongo', // Author for RSS author segment and SEO schema
  authorUrl: 'https://blog.tinongo.org/ueber-uns/', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: '@DerTinongo', // Change for Twitter Cards
  shortTitle: 'Tinongo Blog', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/share.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 900, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: '/logos/icon.png', // Logo used for SEO, RSS, and App manifest
  logo: '/logos/logo.svg',
  backgroundColor: '#f0f0f0', // Used for Offline Manifest
  themeColor: '#822046', // Used for Offline Manifest
  copyright: `Copyright ©${new Date().getFullYear()} Tinongo`, // Copyright string for the RSS feed
}
