const theme = {
  colors: {
    base: '#473be0',
    baseLight: '#8468ff',
    baseDark: '#000fad',
    secondary: '#ef4730',
    secondaryLight: '#ff7b5c',
    secondaryDark: '#b50004',
    tertiary: '#fbf5f5',
    highlight: '#ef4730',
  },
  sizes: {
    maxWidth: '1050px',
    maxWidthCentered: '650px',
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
}

export default theme
