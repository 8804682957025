import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import config from '../utils/siteConfig'

const Header = styled.header`
  background: #fff;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.4);
  width: 100%;
  padding: 1.5em 0;
`
const Nav = styled.nav`
  width: 100%;
  max-width: ${(props) => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 1.5em;

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  li {
    display: inline-block;
    margin-left: 1em;
    &:first-child {
      position: relative;
      margin: 0;
      flex-basis: 80%;
    }
  }

  a {
    font-family: 'Exo 2', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
    text-decoration: none;
    color: rgba(0, 0, 0, 0.54);
    transition: all 0.2s;
    text-transform: uppercase;
    &:hover {
      color: #ef4730;
    }
  }

  img {
    width: 51px;
    height: auto;
  }
`

const activeLinkStyle = {
  color: 'white',
}

const Menu = () => {
  return (
    <Header>
      <Nav>
        <ul>
          <li>
            <Link to="/" activeStyle={activeLinkStyle}>
              <img src={config.logo} alt="Tinongo Blog" />
            </Link>
          </li>
          <li>
            <a
              href="https://www.tinongo.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Website
            </a>
          </li>
        </ul>
      </Nav>
    </Header>
  )
}

export default Menu
