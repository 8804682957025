import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.footer`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: ${(props) => props.theme.sizes.maxWidth};
`

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 1em 0 2em;
  margin: 0 1.5em;
`

const Item = styled.li`
  color: rgba(0, 0, 0, 0.54);
  display: inline-block;
  padding: 0.25em 0;
  width: 100%;
  @media screen and (min-width: ${(props) => props.theme.responsive.small}) {
    width: auto;
  }
  a {
    transition: all 0.2s;
    color: rgba(0, 0, 0, 0.54);
    text-decoration: none;
    &:hover {
      color: ${(props) => props.theme.colors.secondaryDark};
    }
  }
`

const Footer = () => (
  <Wrapper>
    <List>
      <Item>&copy;{new Date().getFullYear()} Tinongo</Item>
      <Item>
        <a
          href="https://www.tinongo.org/impressum"
          rel="noopener noreferrer"
          target="_blank"
        >
          Impressum
        </a>
      </Item>
    </List>
  </Wrapper>
)

export default Footer
